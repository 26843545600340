import { Component, signal, Signal, ViewChild } from '@angular/core';
import * as fromLife from '@life/selectors/life-area.selectors';
import { Store } from '@ngrx/store';
import { ILifeActivity } from '@life/models/life-activity';
import { CloseDrawerComponent } from '@life/components/close-drawer/close-drawer.component';
import { TabNoteComponent } from '@life/components/tabs/tab-note/tab-note.component';
import { TabActivityComponent } from '@life/components/tabs/tab-activity/tab-activity.component';
import { IsFavoriteComponent } from '@core/shared/is-favorite/is-favorite.component';
import {
  ColorSelectComponent,
  ConfirmationDialogComponent,
  InlineEditComponent,
} from '@app/components';
import { ActivityTabStatesComponent } from '@app/activities/components/tabs/activity-tab-states/activity-tab-states.component';
import { ActivityTabAboutComponent } from '@app/activities/components/tabs/activity-tab-about/activity-tab-about.component';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CdkMenuModule } from '@angular/cdk/menu';
import { Dialog } from '@angular/cdk/dialog';
import { ILifeArea } from '@life/models/life-area';
import { filter } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { OgActivityActions, OgLifeNoteActions } from '@life/actions';
import { IconSelectComponent } from '@app/components';
import { Router, ActivatedRoute } from '@angular/router';
import { StopPropagationDirective } from '@core/directives/stop-propagation.directive';
import { ImportanceFilterComponent } from '@components/filters/importance-filter/importance-filter.component';
import { IsFavoriteFilterComponent } from '@components/filters/is-favorite-filter/is-favorite-filter.component';
import { SatisfactionFilterComponent } from '@components/filters/satisfaction-filter/satisfaction-filter.component';
import { selectNotes$ } from '@life/selectors';
import { ILifeNote } from '@life/models/life-note';
import { ElementType } from '@life/models/element.type';

@Component({
  selector: 'hl-activity-drawer',
  standalone: true,
  templateUrl: './activity-drawer.component.html',
  imports: [
    CloseDrawerComponent,
    TabActivityComponent,
    TabNoteComponent,
    TranslateModule,
    CommonModule,
    IsFavoriteComponent,
    InlineEditComponent,
    ConfirmationDialogComponent,
    CdkMenuModule,
    ActivityTabStatesComponent,
    ActivityTabAboutComponent,
    IconSelectComponent,
    StopPropagationDirective,
    ImportanceFilterComponent,
    IsFavoriteFilterComponent,
    SatisfactionFilterComponent,
    ColorSelectComponent,
  ],
})
export class ActivityDrawerComponent {
  activeItem: ILifeActivity;
  @ViewChild('tabAboutComponent') tabAboutComponent: ActivityTabAboutComponent;
  activeItem$ = this.store.select(fromLife.selectActiveDrawerItem$).pipe(
    tap(({ item, activeTabIndex }) => {
      this.activeTab = +activeTabIndex;

      if (
        this.activeItem &&
        this.activeItem.id !== item?.id &&
        this.tabAboutComponent?.descriptionControl?.value !== undefined &&
        this.activeItem.description !==
          this.tabAboutComponent?.descriptionControl?.value
      ) {
        this.updateActivityDescription(
          this.tabAboutComponent?.descriptionControl?.value,
          this.activeItem
        );
      }

      this.activeItem = item as ILifeActivity;

      if (item?.id) {
        this.notes = this.store.selectSignal(selectNotes$(item.id));
      }
    }),
    filter(item => !!item),
    map(({ item }) => item as ILifeActivity)
  );

  canRename = false;
  activeTab = 0;
  notes: Signal<ILifeNote[]> = signal([]);

  lifeAreas = this.store.selectSignal<ILifeArea[]>(fromLife.selectLife$);

  constructor(
    private store: Store,
    private dialog: Dialog,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  updateActivityDescription(
    description: string | null,
    activity: ILifeActivity
  ) {
    this.store.dispatch(
      OgActivityActions.setActivityDescription({
        activity: { ...activity, description },
      })
    );
  }
  addActivityNote(note: Partial<ILifeNote>, activity: ILifeActivity) {
    const newNote = {
      text: note.text,
      elementType: ElementType.LIFE_ACTIVITY,
      elementId: activity.id,
    };
    this.store.dispatch(OgLifeNoteActions.addNote({ note: newNote }));
  }
  updateActivityNote(note: ILifeNote) {
    this.store.dispatch(OgLifeNoteActions.updateNote({ note }));
  }

  updateActivityNoteFocus(note: ILifeNote) {
    this.store.dispatch(OgLifeNoteActions.updateNoteFocus({ note }));
  }
  deleteNote(noteId: string) {
    this.store.dispatch(OgLifeNoteActions.deleteNote({ noteId: noteId }));
  }
  updateActivityFocused(isFocused: boolean, activity: ILifeActivity) {
    this.store.dispatch(
      OgActivityActions.setActivityFocus({
        activity: {
          ...activity,
          isFocused,
        },
      })
    );
  }
  deleteActivity(activity: ILifeActivity) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      maxWidth: '600px',
      data: {
        title: this.translate.instant('ConfirmationDialogDeleteActivityTitle'),
        message: this.translate.instant(
          'ConfirmationDialogDeleteActivityMessage',
          {
            name: activity.name,
          }
        ),
      },
    });

    dialogRef.closed.subscribe(res => {
      if (res) {
        this.store.dispatch(OgActivityActions.deleteActivity({ activity }));
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {
            activeItemId: null,
            activeItemType: null,
            activeTabIndex: null,
          },
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
      }
    });
  }
  rename(name: string, activity: ILifeActivity) {
    this.store.dispatch(
      OgActivityActions.setActivityName({
        activity: { ...activity, name: name },
      })
    );
  }
  updateActivityIcon(icon: string, activity: ILifeActivity) {
    this.store.dispatch(
      OgActivityActions.setActivityIcon({
        activity: { ...activity, icon: icon },
      })
    );
  }

  selectTab(activeItemId: string, activeTabIndex = 0) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        activeItemId,
        activeItemType: 'activity',
        activeTabIndex,
      },
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  statesCount(activity: ILifeActivity) {
    return activity.specificIds?.length + activity.stateIds?.length;
  }
}
