<div class="flex w-full items-center px-3" data-sl="mask">
  <div class="w-full flex items-center border-b justify-between py-3">
    <div class="flex items-center gap-2">
      <div
        class="w-8 h-8 flex items-center justify-center rounded bg-darkgray text-white">
        <i class="fa-solid fa-pie-chart text-white"></i>
      </div>
      <span class="text-dropdowngray">{{
        'DrawerActivityDropdownsLifeArea' | translate
      }}</span>
    </div>
    <div class="flex items-center gap-1" [cdkMenuTriggerFor]="menu">
      <i
        *ngIf="selectedLifeArea"
        class="fa-solid"
        [ngClass]="selectedLifeArea.icon"
        [style.color]="selectedLifeArea.darkColor"></i>
      <span [style.color]="selectedLifeArea.darkColor">{{
        selectedLifeArea.name
      }}</span>
      <i class="fa-light fa-chevron-down"></i>
    </div>
  </div>
</div>
<ng-template #menu>
  <div cdkMenu class="popup-menu gap-y-1" data-sl="mask">
    <button
      cdkMenuItem
      *ngFor="let area of lifeAreas; let i = index"
      class="group popup-menu-btn-life hover:bg-none"
      (click)="handleSelection(area)">
      <div
        [style.background-color]="area.lightColor"
        [ngClass]="area.id === selected ? 'visible' : 'invisible'"
        class="absolute w-full h-full top-0 left-0 -z-[1] group-hover:visible"></div>
      <i
        class="fa-solid"
        [ngClass]="area.icon"
        [style.color]="area.darkColor"></i>
      <span [style.color]="area.darkColor"> {{ area.name }}</span>
    </button>
    <button
      class="group popup-menu-btn-life hover:bg-none"
      (click)="handleSelection(unnasignedLifeArea)">
      <div
        [style.background-color]="unnasignedLifeArea.lightColor"
        [ngClass]="!selected ? 'visible' : 'invisible'"
        class="absolute w-full h-full top-0 left-0 -z-[1] group-hover:visible"></div>
      <i
        class="fa-solid"
        [ngClass]="unnasignedLifeArea.icon"
        [style.color]="unnasignedLifeArea.darkColor"></i>
      <span [style.color]="unnasignedLifeArea.darkColor">
        {{ unnasignedLifeArea.name }}</span
      >
    </button>
  </div>
</ng-template>
