<ng-container *ngIf="activeItem$ | async as activity">
  <section>
    <header
      class="sticky w-full top-0 bg-drawer py-4 z-20 flex flex-col text-gray-600">
      <div class="flex items-center justify-between mt-0.5">
        <div class="flex items-center gap-2">
          <button
            [ngClass]="{
              'pointer-events-none': activity.readonly
            }"
            [cdkMenuTriggerFor]="colorAndIconMenu"
            tabindex="0">
            <i
              class="fa-solid w-5"
              [ngClass]="activity.icon"
              [style.color]="activity.darkColor"></i>
          </button>
          <h6
            class="text-base flex flex-1"
            data-sl="mask"
            [ngStyle]="{ color: activity.darkColor }">
            <hl-inline-edit
              [editable]="canRename"
              (setValue)="rename($event, activity)"
              (cancel)="canRename = false"
              [value]="activity.name"></hl-inline-edit>
          </h6>
          <hl-is-favorite
            [ngClass]="{
              'pointer-events-none': activity.readonly
            }"
            [isFavorite]="activity.isFocused"
            (toggleFavourite)="
              updateActivityFocused($event, activity)
            "></hl-is-favorite>
        </div>

        <div class="flex gap-x-3 text-holismo-grey">
          <button
            [cdkMenuTriggerFor]="menu"
            [ngClass]="{
              'pointer-events-none': activity.readonly
            }">
            <i class="fa-solid px-3 fa-ellipsis-vertical"></i>
          </button>
          <hl-close-drawer></hl-close-drawer>
        </div>
      </div>
      <ul class="tab-container top-[6px]">
        <li
          class="tab"
          tabindex="0"
          (click)="selectTab(activity.id, 0)"
          (keyup.enter)="selectTab(activity.id, 0)"
          [ngClass]="{
            'bg-white font-bold': activeTab === 0,
            'bg-gray-300': activeTab !== 0
          }">
          {{ 'DrawerTabAbout' | translate }}
        </li>
        <li
          class="tab"
          tabindex="0"
          (click)="selectTab(activity.id, 1)"
          (keyup.enter)="selectTab(activity.id, 1)"
          [ngClass]="{
            'bg-white font-bold': activeTab === 1,
            'bg-gray-300': activeTab !== 1
          }">
          {{ 'DrawerActivityTabStates' | translate }}
          <span *ngIf="statesCount(activity) > 0"
            >({{ statesCount(activity) }})</span
          >
        </li>
        <li
          class="tab"
          tabindex="0"
          (click)="selectTab(activity.id, 2)"
          (keyup.enter)="selectTab(activity.id, 2)"
          [ngClass]="{
            'bg-white font-bold': activeTab === 2,
            'bg-gray-300': activeTab !== 2
          }">
          {{ 'DrawerTabNotes' | translate }}
          <span *ngIf="notes().length > 0">({{ notes().length }})</span>
        </li>
      </ul>
    </header>
    <div class="w-full flex flex-col items-center text-headingdark">
      <div class="tab-content w-full">
        <div class="flex flex-col w-full" *ngIf="activeTab === 0">
          <hl-activity-tab-about
            data-sl="mask"
            #tabAboutComponent
            (updateDescription)="updateActivityDescription($event, activity)"
            [element]="activity"
            [lifeAreas]="lifeAreas()"></hl-activity-tab-about>
        </div>
        <div class="flex flex-col w-full" *ngIf="activeTab === 1">
          <hl-activity-tab-states
            [ngClass]="{
              'pointer-events-none': activity.readonly
            }"
            [activity]="activity"></hl-activity-tab-states>
        </div>
        <div *ngIf="activeTab === 2">
          <hl-tab-note
            [notes]="notes()"
            (addNewNote)="addActivityNote($event, activity)"
            (updateNote)="updateActivityNote($event)"
            (updateNoteFocused)="updateActivityNoteFocus($event)"
            (deleteNote)="deleteNote($event)"></hl-tab-note>
        </div>
      </div>
    </div>
  </section>

  <ng-template #colorAndIconMenu>
    <div cdkMenu class="popup-menu">
      <button
        [cdkMenuTriggerFor]="iconMenu"
        cdkMenuItem
        hlStopPropagation
        class="popup-menu-btn justify-between">
        <div>
          <i class="mr-4 fa-solid w-3" [ngClass]="activity.icon"></i>
          <span> {{ 'ContextMenuChangeIcon' | translate }}</span>
        </div>
        <i class="fa-solid fa-arrow-right"></i>
      </button>
    </div>
  </ng-template>

  <ng-template #menu>
    <div cdkMenu class="popup-menu">
      <button
        cdkMenuItem
        (click)="canRename = true"
        class="h-9 w-48 flex items-center justify-start cursor-pointer px-2 hover:bg-gray-500/20 hover:text-gray-400">
        <i class="fa-regular fa-pen mr-4"></i>
        <span> {{ 'ContextMenuRename' | translate }}</span>
      </button>
      <button
        [cdkMenuTriggerFor]="iconMenu"
        cdkMenuItem
        hlStopPropagation
        class="popup-menu-btn justify-between">
        <div>
          <i class="w-3 mr-4 fa-solid" [ngClass]="activity.icon"></i>
          <span> {{ 'ContextMenuChangeIcon' | translate }}</span>
        </div>
        <i class="fa-solid fa-arrow-left"></i>
      </button>
      <button
        cdkMenuItem
        (click)="deleteActivity(activity)"
        class="popup-menu-btn-trash">
        <i class="w-3 fa-solid fa-trash mr-4"></i>
        <span> {{ 'ContextMenuDelete' | translate }}</span>
      </button>
    </div>
  </ng-template>
  <ng-template #iconMenu>
    <div class="popup-menu p-0 shadow-none" cdkMenu>
      <hl-icon-select
        [icons]="[]"
        (selectIcon)="updateActivityIcon($event, activity)"></hl-icon-select>
    </div>
  </ng-template>
</ng-container>
