<ng-container *ngIf="activeItem$ | async as lifeSpecific">
  <section>
    <header class="drawer-header">
      <div class="flex items-center justify-between mt-0.5">
        <div class="flex items-center gap-2">
          <h6
            class="text-base flex flex-1"
            [ngStyle]="{ color: lifeSpecific.darkColor }">
            <hl-inline-edit
              [editable]="canRename"
              (setValue)="rename($event, lifeSpecific)"
              (cancel)="canRename = false"
              [value]="lifeSpecific.name"></hl-inline-edit>
          </h6>
          <hl-is-favorite
            [ngClass]="{
              'pointer-events-none': lifeSpecific.readonly
            }"
            [isFavorite]="lifeSpecific.isFocused"
            (toggleFavourite)="
              updateLifeSpecificFocused($event, lifeSpecific)
            "></hl-is-favorite>
        </div>
        <div class="flex gap-x-3 text-holismo-grey">
          <button
            [ngClass]="{
              'pointer-events-none': lifeSpecific.readonly
            }"
            [cdkMenuTriggerFor]="menu">
            <i class="fa-solid px-3 fa-ellipsis-vertical"></i>
          </button>
          <hl-close-drawer></hl-close-drawer>
        </div>
      </div>
      <ul class="tab-container">
        <li
          class="tab"
          tabindex="0"
          (click)="selectTab(lifeSpecific.id, 0)"
          (keyup.enter)="selectTab(lifeSpecific.id, 0)"
          [ngClass]="{
            'bg-white font-bold': activeTab === 0,
            'bg-gray-300': activeTab !== 0
          }">
          {{ 'DrawerTabAbout' | translate }}
        </li>
        <li
          class="tab"
          tabindex="0"
          (click)="selectTab(lifeSpecific.id, 1)"
          (keyup.enter)="selectTab(lifeSpecific.id, 1)"
          [ngClass]="{
            'bg-white font-bold': activeTab === 1,
            'bg-gray-300': activeTab !== 1
          }">
          {{ 'DrawerTabActivities' | translate }}
          <span *ngIf="activityCount(lifeSpecific) > 0"
            >({{ lifeSpecific.activityIds.length }})</span
          >
        </li>
        <li
          class="tab"
          tabindex="0"
          *ngrxLet="notes() as notes"
          (click)="selectTab(lifeSpecific.id, 2)"
          (keyup.enter)="selectTab(lifeSpecific.id, 2)"
          [ngClass]="{
            'bg-white font-bold': activeTab === 2,
            'bg-gray-300': activeTab !== 2
          }">
          {{ 'DrawerTabNotes' | translate }}
          <span *ngIf="notes.length > 0">({{ notes.length }})</span>
        </li>
      </ul>
    </header>
    <div class="w-full flex flex-col items-center text-headingdark">
      <div class="tab-content w-full">
        <div class="flex flex-col w-full" *ngIf="activeTab === 0">
          <hl-tab-about
            data-sl="mask"
            #tabAboutComponent
            (updateDescription)="
              updateLifeSpecificDescription($event, lifeSpecific)
            "
            [element]="lifeSpecific"></hl-tab-about>
        </div>
        <div *ngIf="activeTab === 1">
          <hl-tab-activity
            data-sl="mask"
            [ngClass]="{
              'pointer-events-none': !!lifeSpecific.readonly
            }"
            [element]="lifeSpecific"
            (manageActivity)="
              manageActivity($event, lifeSpecific)
            "></hl-tab-activity>
        </div>
        <div *ngIf="activeTab === 2">
          <hl-tab-note
            [notes]="notes()"
            (updateNote)="updateLifeSpecificNote($event)"
            (updateNoteFocused)="updateLifeSpecificNoteFocus($event)"
            (deleteNote)="deleteNote($event)"
            (addNewNote)="addNewNote($event, lifeSpecific)"></hl-tab-note>
        </div>
      </div>
    </div>
  </section>

  <ng-template #menu>
    <div cdkMenu class="popup-menu">
      <button cdkMenuItem (click)="canRename = true" class="popup-menu-btn">
        <i class="fa-regular fa-pen mr-4"></i>
        <span> {{ 'ContextMenuRename' | translate }}</span>
      </button>
      <button
        cdkMenuItem
        (click)="delete(lifeSpecific)"
        class="popup-menu-btn-trash">
        <i class="fa-solid fa-trash mr-4"></i>
        <span> {{ 'ContextMenuDelete' | translate }}</span>
      </button>
    </div>
  </ng-template>
</ng-container>
