import {
  Component,
  computed,
  ElementRef,
  HostListener,
  ViewChild,
} from '@angular/core';
import * as fromLifeFilter from '@life/selectors/life-filters.selectors';
import { Store } from '@ngrx/store';
import { LifeFiltersActions } from '@life/actions';
import {
  MatCalendarCellClassFunction,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { InputSearchComponent } from '@app/components';
import {
  CdkMenu,
  CdkMenuBar,
  CdkMenuItem,
  CdkMenuTrigger,
} from '@angular/cdk/menu';
import { NgClass, NgIf } from '@angular/common';
import { LetDirective } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { ActionsFilterComponent } from '@components/filters/actions-filter/actions-filter.component';
import { ImportanceFilterComponent } from '@components/filters/importance-filter/importance-filter.component';
import { SatisfactionFilterComponent } from '@components/filters/satisfaction-filter/satisfaction-filter.component';
import { UrgencyFilterComponent } from '@components/filters/urgency-filter/urgency-filter.component';
import { IsFavoriteFilterComponent } from '@components/filters/is-favorite-filter/is-favorite-filter.component';
import { selectLifeDates$ } from '@life/selectors';
import { ConnectedPosition } from '@angular/cdk/overlay';

@Component({
  selector: 'hl-filters',
  templateUrl: './filters.component.html',
  standalone: true,
  imports: [
    InputSearchComponent,
    CdkMenuTrigger,
    NgClass,
    NgIf,
    LetDirective,
    CdkMenu,
    MatDatepickerModule,
    TranslateModule,
    CdkMenuItem,
    ActionsFilterComponent,
    ImportanceFilterComponent,
    SatisfactionFilterComponent,
    UrgencyFilterComponent,
    IsFavoriteFilterComponent,
    CdkMenuBar,
  ],
})
export class FiltersComponent {
  @ViewChild('calendarMenuTrigger') calendarMenuTrigger: CdkMenuTrigger;
  @ViewChild('calendarMenuContainer') calendarMenuContainer: ElementRef;
  @ViewChild('btn') btn: ElementRef;
  filterCount = this.store.selectSignal(fromLifeFilter.selectLifeFiltersCount$);
  lifeFilters = this.store.selectSignal(fromLifeFilter.selectLifeFilters$);
  lifeDates = this.store.selectSignal(selectLifeDates$);

  selectedDate = computed(() => {
    return Number.isInteger(this.lifeFilters().selectedDate)
      ? new Date(this.lifeFilters().selectedDate as number)
      : null;
  });

  maxDate = new Date();
  calendarPosition: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    },
  ]

  constructor(private store: Store) {}
  clearFilters() {
    this.store.dispatch(LifeFiltersActions.clearLifeFilters());
  }

  manageActiveFilters(actions: number[]) {
    this.store.dispatch(LifeFiltersActions.setLifeFiltersActions({ actions }));
  }

  manageImportanceFilters(importance: number[]) {
    this.store.dispatch(
      LifeFiltersActions.setLifeFiltersImportance({ importance })
    );
  }

  manageUrgencyFilters(urgency: number[]) {
    this.store.dispatch(LifeFiltersActions.setLifeFiltersUrgency({ urgency }));
  }

  manageSatisfactionFilters(satisfaction: number[]) {
    this.store.dispatch(
      LifeFiltersActions.setLifeFiltersSatisfaction({ satisfaction })
    );
  }
  manageFavoriteFilters(focus: boolean[]) {
    this.store.dispatch(LifeFiltersActions.setLifeFiltersFocus({ focus }));
  }

  manageSearchFilter(search: string) {
    this.store.dispatch(LifeFiltersActions.setLifeFiltersSearch({ search }));
  }

  hasDateIncluded(cellDate: Date, selectedDays: Date[]) {
    return selectedDays.find(date => {
      return (
        date.getDate() === cellDate.getDate() &&
        date.getMonth() === cellDate.getMonth()
      );
    });
  }

  highLightDates: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    const lifeDates = this.lifeDates();
    // Only highlight dates inside the month view.
    let highlightClass = '';
    if (view === 'month') {
      if (this.hasDateIncluded(cellDate, lifeDates.changeDates)) {
        highlightClass = 'has-input-date';
      }
      if (
        cellDate.getDate() === lifeDates.startDate.getDate() &&
        cellDate.getMonth() === lifeDates.startDate.getMonth()
      ) {
        highlightClass = 'life-start-date';
      }
    }

    return highlightClass;
  };

  selectDate(date: Date | null) {
    const dayIsToday =
      new Date().setHours(0, 0, 0, 0) === date?.setHours(0, 0, 0, 0);

    this.store.dispatch(
      LifeFiltersActions.setLifeFiltersDate({
        date: dayIsToday ? null : date?.setHours(23, 0, 0, 0) || null,
      })
    );

    this.calendarMenuTrigger.close();
  }

  manageGroupFilter(groupName: string) {
    //todo
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.calendarMenuTrigger.close();
  }

  @HostListener('document:click', ['$event'])
  clickOut(event: any) {
    if (
      this.calendarMenuTrigger.isOpen() &&
      !this.calendarMenuContainer.nativeElement.contains(event.target) &&
      !this.btn.nativeElement.contains(event.target)
    ) {
      this.calendarMenuTrigger.close();
    }
  }
}
